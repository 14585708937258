import objectPath from 'object-path'
import DateUtil from './DateUtil';

export default class ArrayUtil {

    static isNonEmptyArray(e) {
      return (Array.isArray(e) && e.length) ? true : false
    }

    static arraysHaveMatch(a1, a2) {
        var match = a1.filter(element => a2.includes(element));
        return ArrayUtil.isNonEmptyArray(match);
      }

      static containsAnyFromArray(string, array){
        for (var i = 0; i < array.length; i++) {
          if (string.indexOf(array[i]) > -1) {
            return true;
          }
        }
        return false;
      }


      static sortByDateUpdated(array, descending = true){
        return array.sort(
          function(a,b){
          var aValue = DateUtil.unixTime(a.updatedTime);
          var bValue = DateUtil.unixTime(b.updatedTime);
          if(aValue <= bValue) { return descending ? 1 : -1; }
          if(aValue > bValue) { return descending ? -1 : 1; }
          return 0;
        });
      }

      static sortByDateCreated(array, descending = true){
        return array.sort(
          function(a,b){
          var aValue = DateUtil.unixTime(a.createdTime);
          var bValue = DateUtil.unixTime(b.createdTime);
          if(aValue <= bValue) { return descending ? 1 : -1; }
          if(aValue > bValue) { return descending ? -1 : 1; }
          return 0;
        });
      }

      static sortByNumberProp(array, propName, descending = true){
        return array.sort(
          function(a,b){
          var aValue = parseInt(objectPath.get(a, propName, 0));
          var bValue = parseInt(objectPath.get(b, propName, 0));
          if(aValue <= bValue) { return descending ? 1 : -1; }
          if(aValue > bValue) { return descending ? -1 : 1; }
          return 0;
        });
      }

      static sortByName(array){
        return array.sort(function(a, b){
          if(a.name <= b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
      })
    }

      


      static addToArrayIfNotExist(array, item) {

        if(Array.isArray(array) && array.indexOf(item) === -1){
          array.push(item);
        }
        return array;
      }
      static removeFromArrayIfExist(array, item) {
        if(Array.isArray(array)){
          var index = array.indexOf(item);
          if (index > -1) {
            array.splice(index, 1);
          }
        } 
        return array;
      }


      static addToArrayIfNotExistByProp(array, item, propName) {

        if(Array.isArray(array)){
          var index = array.findIndex(x => x[propName] === item[propName]);
          if (index === -1) {
            array.push(item);
          }
        }
        return array;
      }
      static removeFromArrayIfExistByProp(array, item, propName) {
        if(Array.isArray(array)){
          var index = array.findIndex(x => x[propName] === item[propName]);
          if (index > -1) {
            array.splice(index, 1);
          }
        } 
        return array;
      }

      static mapIdNameToValueLabel(array){
        return Array.isArray(array) ? array.map((x) => {return {"label": x.name, "value": x.id}}) : array;
      }


      

      static getRandItemIfExist(array){
        return Array.isArray(array) && array.length ? array[Math.floor(Math.random() * array.length)] : null;
      }

      static getNext(array, value, key = 'id'){
        var next = null;
        if(Array.isArray(array)){
          for (var i = 0; i < array.length; i++) { 
            var iId = objectPath.get(array,i+'.'+key, null);
            var iNext = objectPath.get(array,(i+1), null);
            if(next === null && iId === value && iNext){
              next = iNext;
            }
          }
        }
        return next;
      }

      static getPrev(array, value, key = 'id'){
        var prev = null;
        if(Array.isArray(array)){
          for (var i = 0; i < array.length; i++) { 
            var iId = objectPath.get(array,i+'.'+key, null);
            var iPrev = objectPath.get(array,(i-1), null);
            if(prev === null && iId === value && iPrev){
              prev = iPrev;
            }
          }
        }
        return prev;
      }

      static getSimpleNext(array, index){
        return Array.isArray(array) && array[(index+1)] ? array[(index+1)] : null;
      }

      static getSimplePrev(array, index){
        return Array.isArray(array) && index > 0 && array[(index-1)] ? array[(index-1)] : null;
      }

      static isLast(array, value, key = 'id'){
        if(Array.isArray(array)){
          var lastItemValue = objectPath.get(array,(array.length-1)+'.'+key,null);
          return lastItemValue === value;
        }
        return false
      }


      static getItemIndex(array, value, key = 'id'){
        var index = null;
        if(Array.isArray(array)){
          array.forEach((o,i) => {
            if(index === null && objectPath.get(o,key) === value){
              index = i;
            }
          });
      }
      return index;
    }

    static removeDuplicates(arr) {
      var seen = {};
      var ret_arr = [];
      for (var i = 0; i < arr.length; i++) {
          if (!(arr[i] in seen)) {
              ret_arr.push(arr[i]);
              seen[arr[i]] = true;
          }
      }
      return ret_arr;
  
  }

  }
  