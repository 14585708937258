import objectPath from "object-path";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as discussionActions from '../../../actions/discussion';
import * as projectActions from '../../../actions/project';
//import * as constants from '../../constants/constants';
import { Button, Grid, Hidden, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import GroupIcon from '@material-ui/icons/Group';
import LockIcon from '@material-ui/icons/Lock';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PageviewIcon from '@material-ui/icons/Pageview';
import classnames from 'classnames';
import { withSnackbar } from "notistack";
import ComponentLoading from "../../../components/ComponentLoading";
import PageHeading from "../../../components/PageHeading";
import HeadingAddBtn from "../../../components/buttons/HeadingAddBtn";
import HeadingGeneralBtn from "../../../components/buttons/HeadingGeneralBtn";
import ConfirmDialog from "../../../components/dialogs/ConfirmDialog";
import ProjectPreviewCard from "../../../components/project/ProjectPreviewCard";
import styles from "../../../theme/styles";
import ArrayUtil from "../../../utils/ArrayUtil";
import FormatterUtil from "../../../utils/FormatterUtil";
import SnackBarUtil from "../../../utils/SnackBarUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import ProjectDiscussionsMasonry from "./ProjectDiscussionsMasonry";

const entityName = "Discussion";

class ProjectDashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      discussions: [],
      dataLoading: true,
      expandedCardIds: [],
      showProjectMobileContent: false,
    }
  }


  renderConfirmationDialog = () => {
    const { itemToDelete } = this.state;
    if(itemToDelete){
      return (<ConfirmDialog 
      message={TranslatorUtil.t('Please confirm you wish to remove ')+FormatterUtil.camelToNormalForm(entityName)+' '+itemToDelete.name}
      onClose={() => this.onDeleteClicked(null)}
      onConfirm={() => this.handleDiscussionDelete(itemToDelete.id)}/>)
    }
  }

  onDeleteClicked = (item) => {
    this.setState({
      itemToDelete: item
    });
  }

  handleDiscussionDelete = (id) => {
    const { actions, selectedProject } = this.props;
    return selectedProject ? actions.deleteDiscussion(selectedProject.id, id).then(response => this.handleResponse(response)) : null;
  }

  

  handleResponse = response => {
    
    const { enqueueSnackbar } = this.props;
    this.onDeleteClicked(null);//hide confirm
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      SnackBarUtil.showSuccessMessage(response, enqueueSnackbar);
      
      //reload
      this.mountItems(true);
    }
  }

  mountDiscussions = (projectID) => {
    const _this = this;
    const { actions } = this.props;
    AccessUtil.isAtLeastRespondentForProject(projectID,true);
    actions.getDiscussions(projectID).then((response) => {
      return _this.setState({
        discussions: response.data
      });
    });
  }

  finishLoading = () => {
    const { selectedProject } = this.props;
    if(!selectedProject){
      window.location.replace("/404");
    }
    return this.setState({
      dataLoading: false
    });
  }

  mountItems = () => {
    
    const { selectedProject, actions, match } = this.props;
    var projectID = objectPath.get(match,'params.projectId');
    if(selectedProject && selectedProject.id === projectID){
      this.mountRelatedItems();
    } else {
      if(projectID){
        actions.getProject(projectID).then(() => this.mountRelatedItems());
      }
      
    }
    
  }

  mountRelatedItems = () => {
    const { match } = this.props;
    const _this = this;
    var projectID = objectPath.get(match,'params.projectId');
    if(projectID){
      Promise.all([
        _this.mountDiscussions(projectID)
      ]).then(()=>{ _this.finishLoading() }).catch(function(err) {
        _this.finishLoading()
      });
    }
    
  }


  componentDidMount() {
    this.mountItems();
  }


  toggleCardById = (id) => {
    const { expandedCardIds } = this.state;
    if(expandedCardIds.includes(id)){
      this.setState({expandedCardIds: ArrayUtil.removeFromArrayIfExist(expandedCardIds, id)});
    } else {
      this.setState({expandedCardIds: ArrayUtil.addToArrayIfNotExist(expandedCardIds, id)});
    }
  }

  render() {
    const { dataLoading, showShowMoreBtn, discussions, itemToDelete, showMobilePreview } = this.state;
    const { classes, selectedProject, match, profiles, userInfo, history } = this.props;

    var projectID = objectPath.get(match,'params.projectId');
    

    return <div className="project-dashboard-wrap">
      <PageHeading heading={TranslatorUtil.t(FormatterUtil.camelToNormalForm(entityName)+"s") + (selectedProject ? " - "+selectedProject.name : "")} actions={selectedProject ? 
        <React.Fragment>
          <HeadingGeneralBtn title={TranslatorUtil.t("Preview")} icon={<PageviewIcon />} redirectToUrl={UrlBuilderUtil.getProjectPreview(projectID)} />
          {AccessUtil.isAtLeastObserverForProject(projectID) ? <React.Fragment>
          <HeadingGeneralBtn title={TranslatorUtil.t("Analytics")} icon={<EqualizerIcon />} redirectToUrl={UrlBuilderUtil.getProjectStats(projectID)} />
        </React.Fragment> : null }
        {AccessUtil.isAtLeastModeratorForProject(projectID) ? <React.Fragment>
          <HeadingGeneralBtn title={TranslatorUtil.t("Notify users")} icon={<NotificationsActiveIcon />} redirectToUrl={UrlBuilderUtil.getProjectNotifyUsers(projectID)} />
          <HeadingGeneralBtn title={TranslatorUtil.t("Users")} icon={<GroupIcon />} redirectToUrl={UrlBuilderUtil.getProjectUsersEdit(projectID)} />
          <HeadingGeneralBtn title={TranslatorUtil.t("Edit")} icon={<EditIcon />} redirectToUrl={UrlBuilderUtil.getProjectEdit(projectID)} />
        </React.Fragment> : null }
                                
                                {AccessUtil.isProjectLocked(selectedProject) ? <LockIcon color="disabled" /> : AccessUtil.isAtLeastModeratorForProject(projectID) ? <HeadingAddBtn redirectToUrl={UrlBuilderUtil.getDiscussionCreate(projectID)} /> : null }
                                </React.Fragment> : null} />
      {dataLoading && discussions.length < 1 ? <ComponentLoading /> :
        <div>
            {selectedProject && <Grid container className="gridContainer">
                
                <Grid item xs={12} md={6} lg={4}>
                <Hidden xsDown>
                  <ProjectPreviewCard 
                  company={CompanyUtil.get()}
                  hideTerms={true}
                  project={selectedProject} />
                </Hidden>
                <Hidden smUp>
                  <Button className="px-0" disableRipple onClick={() => this.setState({showMobilePreview: !showMobilePreview})}>{TranslatorUtil.t(showMobilePreview ? "Hide project information" : "Show project information")}</Button>
                  {
                    showMobilePreview ? <ProjectPreviewCard 
                    company={CompanyUtil.get()}
                    hideTerms={true}
                    project={selectedProject} /> : null
                  }
                </Hidden>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <ProjectDiscussionsMasonry
                    showAsMasonry={true}
                    headingText={TranslatorUtil.t("Discussions")}
                    discussions={discussions}
                    profiles={profiles}
                    project={selectedProject}
                    userInfo={userInfo}
                    onDeleteClicked={this.onDeleteClicked}
                    />
                </Grid>
          
          </Grid> }
          { itemToDelete ? this.renderConfirmationDialog() : null}
          { dataLoading &&  discussions.length > 0 ? <ComponentLoading /> : null }
          { !dataLoading &&  discussions.length < 1 ? <div className={classnames(classes.textCenter, classes.contentWithoutHeader)}><Typography variant="body2" className="muted">{TranslatorUtil.t("No results")}</Typography>
          {AccessUtil.isAtLeastModeratorForProject(projectID) ? <div className="p-2"><Button size="large" color="secondary" variant="contained" onClick={() => history.push(UrlBuilderUtil.getDiscussionCreate(projectID))}>{TranslatorUtil.t("Add discussion")}</Button></div> : null }
          </div> : null }
          { !dataLoading && showShowMoreBtn ? <div className="gridItem"><br/><br/><Button size="large" color="primary" fullWidth={true} variant="outlined" onClick={this.showMore}>{TranslatorUtil.t("Show More")}</Button></div> : null}
          
          </div>}
    </div>
  }
}


function mapStateToProps(state) {
  return {
    selectedProject: state.selectedProject,
    profiles: state.profiles,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...projectActions,
        ...discussionActions,
      },
      dispatch
    )
  };
}


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDashboard))));
