import * as types from "../constants/actionTypes";
import { auth
  //, GoogleAuthProvider, 
  //FacebookAuthProvider,
   //firedatabaseApi 
  } from '../firebase';
   import * as ajaxCalls from "./ajaxCalls.js";

import * as constants from "../constants/constants";
import LocalStorageUtil from "../utils/LocalStorageUtil.js";
import { invitationPropKeys } from "../containers/pages/invitation/Invitation.js";

export function setUserInfo(info) {
  return function(dispatch) {
    if (info) {
      return dispatch(success(info));
    } else {
      return dispatch(error());
    }
  };
}

export function loginWithEmail(user) {
  return function(dispatch) {
    return auth.signInWithEmailAndPassword(String(user.email).toLowerCase(), user.password)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_LOGIN)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}

export function logoutUser() {
  LocalStorageUtil.remove(invitationPropKeys.invitationCompanyId);
  LocalStorageUtil.remove(invitationPropKeys.invitationProjectId);
  LocalStorageUtil.remove(invitationPropKeys.invitationDiscussionId);
  return function(dispatch) {
    return auth.signOut()
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_LOGOUT)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}


export function userRegistration(user) {
  return function(dispatch) {
    return auth.createUserWithEmailAndPassword(String(user.email).toLowerCase(), user.password)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_SIGNIN)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}



export function resetPassword (email) {
  
  var actionCodeSettings = {
    url: constants.APP_DOMAIN,
    handleCodeInApp: true
  };
  return function(dispatch) {
    return auth.sendPasswordResetEmail(email, actionCodeSettings)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.USER_RESET_PASSWORD)),
        error => dispatch(ajaxCalls.ajaxCallError(error))
      );
  };
}


function success(response) {
  return {
    type: types.SET_USER_INFO_SUCCESS,
    data: response
  };
}

function error() {
  return {
    type: types.SET_USER_INFO_ERROR,
    data: null
  };
}
