import { withStyles } from "@material-ui/core/styles";
import { withSnackbar } from "notistack";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import ComponentLoading from "../../components/ComponentLoading";
import * as collections from "../../constants/firebaseCollections";
import styles from "../../theme/styles";
import TranslatorUtil from "../../utils/TranslatorUtil";

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import CompanyUtil from "../../utils/projectBased/CompanyUtil";

import ForumIcon from "@material-ui/icons/Forum";
import firestoreApi from "../../firebase";
import FirestoreCollectionUtil from "../../utils/FirestoreCollectionUtil";
import SectionHeading from "../SectionHeading";
import DateUtil from "../../utils/DateUtil";
import { Alert } from "@material-ui/lab";
import ArrayUtil from "../../utils/ArrayUtil";

class UserProjectReactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      discussionId: null,
      discussion: null,
      discussions: [],
      projectMessages: [],
      dataLoading: true,
    };
  }

  componentDidMount() {
    Promise.all([this.mountProjectMessages(), this.mountDiscussions()]).then(
      () => {
        return this.setState({
          dataLoading: false,
        });
      }
    );
  }

  mountProjectMessages = async () => {
    const { selectedProject, userId } = this.props;
    if (selectedProject && userId) {
      return firestoreApi
        .collectionGroup(collections.MESSAGES)
        .where("byUserId", "==", userId)
        .where("projectId", "==", selectedProject.id)
        .where("isProjectRespondent", "==", true)
        .get()
        .then((querySnapshot) => {
          this.setState({
            projectMessages: ArrayUtil.sortByDateCreated(FirestoreCollectionUtil.parseCollectionData(
                querySnapshot
              )),
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  mountDiscussions = async () => {
    const { selectedProject, userId } = this.props;
    if (selectedProject && userId) {
      return firestoreApi
        .collection(
          CompanyUtil.getCollectionPathWithCompanyPrefix(collections.PROJECTS, [
            selectedProject.id,
            collections.DISCUSSIONS,
          ])
        )
        .get()
        .then((querySnapshot) => {
          this.setState({
            discussions: FirestoreCollectionUtil.parseCollectionData(
              querySnapshot
            ),
          });
        });
    } else {
      return Promise.resolve();
    }
  };

  render() {
    const {
      dataLoading,
      discussions,
      discussionId,
      projectMessages,
    } = this.state;

    const filteredProjectMessages = projectMessages.filter(
      (x) => discussionId === null || x.discussionId === discussionId
    );

    return (
      <div>
        <SectionHeading text={TranslatorUtil.t("User reactions")} icon={<ForumIcon />} />
        {dataLoading ? (
          <ComponentLoading />
        ) : (
          <div className="narrowMaxMediumWidthPart p-2">
            <div className="mb-2">
                  <FormControl className="w-100">
                    <InputLabel id="dicussions-select-label">
                      {TranslatorUtil.t("Select discussion")}
                    </InputLabel>
                    <Select
                      labelId="dicussions-select-label"
                      value={discussionId ? discussionId : "all"}
                      
                      onChange={(event) =>
                        this.setState({
                          discussionId: event.target.value !== "all" ? event.target.value : null,
                          discussion: event.target.value !== "all" ? discussions.find(
                            (x) => x.id === event.target.value
                          ) : null,
                        })
                      }
                    >
                        <MenuItem value={"all"} key={0}>
                          <em>{TranslatorUtil.t("All")}</em>
                        </MenuItem>
                      {discussions.map((discussion) => (
                        <MenuItem value={discussion.id} key={discussion.id}>
                          {discussion.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
            {filteredProjectMessages.length === 0 ? (
              <Alert severity="info">
                {TranslatorUtil.t("No messages")}
              </Alert>
            ) : (
              <React.Fragment>

                
                {filteredProjectMessages.map((message, index) => (
                  <Paper
                    elevation={1}
                    className={"p-2 messagePaper mb-2"}
                    component="div"
                    style={{ display: "block" }}
                    key={index}
                  >
                    <Typography
                      component="div"
                      className={"messageTextWrap"}
                      color="textPrimary"
                    >
                      {message.message}
                    </Typography>
                    <Grid container>
                      <Grid item xs={6}>
                        <small className="messageDate">
                          {DateUtil.displayTimeFromNow(
                            message.createdTime,
                            TranslatorUtil.getUserLangFromStore()
                          )}
                        </small>
                        <small className="messageUserId hidden">
                          {message.byUserId}
                        </small>
                        <small className="messageId hidden">{message.id}</small>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedProject: state.selectedProject,
  userInfo: state.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({}, dispatch),
});

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(
      connect(mapStateToProps, mapDispatchToProps)(UserProjectReactions)
    )
  )
);
