import {
  Badge,
  Box,
  Button,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import WarningIcon from "@material-ui/icons/Warning";
import classnames from "classnames";
import objectPath from "object-path";
import React from "react";
import { withRouter } from "react-router-dom";
import styles from "../../theme/styles";
import ArrayUtil from "../../utils/ArrayUtil";
import DateUtil from "../../utils/DateUtil";
import AccessUtil from "../../utils/projectBased/AccessUtil";
import UsersUtil from "../../utils/projectBased/UsersUtil";
import TranslatorUtil from "../../utils/TranslatorUtil";
import UserAvatar from "./UserAvatar";
import SectionHeading from "../SectionHeading";
import PeopleIcon from '@material-ui/icons/People';

class RespondentsProjectChallange extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showAll: false,
      showOnlyTopUsersCount: 5
    };
  }

  renderRows = () => {
    const { projectId } = this.props;

    let rows = [];

    if (this.getProfilesWithChallenges()) {
      const sortedProfiles = ArrayUtil.sortByNumberProp(
        this.getProfilesWithChallenges(),
        "messagesCount_p_" + projectId,
        true
      );
      sortedProfiles.filter((x,i) => this.state.showAll || i < this.state.showOnlyTopUsersCount).forEach((row, index) => {
        rows.push(
          <TableRow key={index}>
            <TableCell className="user-avatar-col-cell">{this.renderUserProfile(row)}</TableCell>
            <TableCell className="w-90px">
              <strong>{objectPath.get(
                row,
                "messagesCount_p_" + projectId,
                <WarningIcon fontSize="small" color="error" />
              )}</strong>
            </TableCell>
            <TableCell className="w-100px">
              <small>{row.lastMessageDateTime
                ? DateUtil.displayTimeFromNow(
                    row.lastMessageDateTime,
                    TranslatorUtil.getUserLangFromStore()
                  )
                : " "}</small>
            </TableCell>
          </TableRow>
        );
      });
    }

    return rows;
  };

  renderHeaderCell = (content, className = null) => {
    return (
      <TableCell className={className}>
        <small><strong>{content}</strong></small>
      </TableCell>
    );
  };

  renderHeader = () => {
    return (
      <TableRow>
        {this.renderHeaderCell(TranslatorUtil.t(""))}
        {this.renderHeaderCell(TranslatorUtil.t("Reactions count"), "w-90px")}
        {this.renderHeaderCell(TranslatorUtil.t("Last activity"), "w-100px")}
        
      </TableRow>
    );
  };

  renderUserProfile = (user) => {
    const { projectId, profiles } = this.props;
    return (
      <ListItem dense className={"p-0"}>
        <ListItemIcon>
          <Badge
            classes={{ badge: "bg-white" }}
            invisible={
              UsersUtil.getUserProjectIcon(user, projectId, profiles) === null
            }
            badgeContent={
              <React.Fragment>
                {UsersUtil.getUserProjectIcon(user, projectId, profiles)}
                {/* <span> {UsersUtil.getUserProjectMessagesCount(user, projectId)}</span> */}
              </React.Fragment>
            }
          >
            <UserAvatar
              userId={user.id}
              menuItems={{
                withChat: AccessUtil.isAtLeastModeratorForProject(projectId),
                withSupport: AccessUtil.isAtLeastModeratorForProject(projectId),
                withDialog: AccessUtil.isAtLeastObserver(),
              }}
            />
          </Badge>
        </ListItemIcon>
        <ListItemText primary={user.displayName} />
      </ListItem>
    );
  };

  getProfilesWithChallenges = () => {
    const { profiles, projectId } = this.props;
    return ArrayUtil.isNonEmptyArray(profiles)
      ? profiles.filter((profile) => {
          return UsersUtil.getUserProjectMessagesCount(profile, projectId) > 0;
        })
      : [];
  };

  render() {
    const { projectId, classes } = this.props;

    return projectId &&
      this.getProfilesWithChallenges() &&
      this.getProfilesWithChallenges().length ? (
      <Box p={2} className="respondentsProjectChallangeWrap">
        <SectionHeading
          icon={<PeopleIcon />}
          text={TranslatorUtil.t("Most active respondents")}
        />
        <Box px={2}>
        <Table className={classnames(classes.table)}>
          <TableHead>{this.renderHeader()}</TableHead>
          <TableBody>{this.renderRows()}</TableBody>
        </Table>
        {!this.state.showAll && this.getProfilesWithChallenges().length > this.state.showOnlyTopUsersCount ? <Box p={3} className="text-center"><Button
          variant="text"
          color="default"
          size="small"
          onClick={() => this.setState({ showAll: true })}
        >
          {TranslatorUtil.t("Show all")}
        </Button></Box> : null}
        </Box>
      </Box>
    ) : null;
  }
}

export default withRouter(
  withStyles(styles, { withTheme: true })(RespondentsProjectChallange)
);
