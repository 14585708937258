import * as constantsConstants from '../constants/constants';
var accents = require('remove-accents');
export default class FormatterUtil {
  static bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  static getDeepCopy(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  static parseJsonSafe(obj){
    try {
      return JSON.parse(obj);
    } catch(ex){
      return null;
    }
}

  static getRemoteFileName(fileName){
    return FormatterUtil.guid()+'.'+FormatterUtil.getFileExtension(fileName);
  }

  static keywordEncodeURI(keyword = ''){
    //remove amp
    keyword = keyword.replace('&','%26');
    //remove question mark
    keyword = keyword.replace('?','%3F');
    return encodeURI(keyword);
  }

  static keywordDecodeURI(keyword = ''){
    //remove amp
    keyword = keyword.replace('%26','&');
    //remove question mark
    keyword = keyword.replace('%3F','?');
    return decodeURI(keyword);
  }

  static removeAccent(str){
    return accents.remove(str);
  }


  static formatDays(days){
    return days + ' ' + (parseInt(days) === 1 ? 'day' : 'days');
  }

  static formatNZDollar(number){
    //if(Intl && Intl.NumberFormat){ // old safari (<10) throws Error 
      //return Intl.NumberFormat(constantsConstants.DEFAULT_LOCALE, { maximumSignificantDigits: 3, style: "currency", currency: constantsConstants.DEFAULT_CURRENCY  }).format(number);
    //} else {
      let result = number.toLocaleString(constantsConstants.DEFAULT_LOCALE, 
        {
          style: "currency",
          currency: constantsConstants.DEFAULT_CURRENCY,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
          //maximumSignificantDigits: 3
        });
      return result;
    //}
    
  }


  static getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  static formatPercentage(number, decimalPlaces = 0){
    var output = number;
    if(number >= 0){
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.floor(number);
    } else {
      output = decimalPlaces > 0 ? parseFloat(number).toFixed(decimalPlaces) : Math.ceil(number);
    }
    return output.toString()+'%';
  }

  static camelToNormalForm(string){
    return FormatterUtil.trimLeft(string
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([a-z])/g, ' $1$2')
      .replace(/ +/g, ' '))
  }

  static trimLeft(string) {
    if(string == null) return string;
    return string.replace(/^\s+/g, '');
  };

  static getIconText(string) {
    if(string == null) return ' ';
    return string.trim().charAt(0).toUpperCase();
  };

  static formatActionNameForErrorMessage(actionName){
    actionName = FormatterUtil.replaceAll(actionName,'GET_', '');
    actionName = FormatterUtil.replaceAll(actionName,'_', ' ');
    return actionName.toLowerCase();
  }

  static replaceAll(str, find, replace) {
    return str.toString().replace(new RegExp(find, 'g'), replace);
  } 


  static hashCode(str) {
    var hash = 0, i, chr;
    if (str.length === 0) return str;
    for (i = 0; i < str.length; i++) {
      chr   = str.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  };

  static hashCodeShort(str,length = 6) {
    return str ? window.btoa(str).substring(0,length) : str;
  };


  static encodeObject(obj){
    return obj ? window.btoa(decodeURIComponent(encodeURIComponent(JSON.stringify(obj)))) : obj;
  }

  static decodeObject(obj){
    return obj ? decodeURIComponent(encodeURIComponent(window.atob(JSON.parse(obj)))) : obj;
  }

  static encodeString(str){
    return str ? window.btoa(decodeURIComponent(encodeURIComponent(str))) : str;
  }

  static decodeString(str){
    return str ? decodeURIComponent(encodeURIComponent(window.atob(str))) : str;
  }

  static guid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0;
      var v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
  }


  static getFileExtension(fileName){
    return fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
  }


  static stripHtml(string){
    return string.replace(/<[^>]*>?/gm, '');
  }

  static removeAbsolutePathFromURL(url){
    return url.replace(/^.*\/\/[^/]+/, '');
  }

  static getSlugVersion(string){
    return FormatterUtil.removeAccent(string).replaceAll(' ', '_');
  }

  static stringToColor = (str) => {
    let hash = 0;
    str.split('').forEach(char => {
      hash = char.charCodeAt(0) + ((hash << 5) - hash)
    })
    let colour = '#'
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff
      colour += value.toString(16).padStart(2, '0')
    }
    return colour
  }

  static stringToRGBColor = (str, alpha = 1) => {
    var hash = 0;
    if (str.length === 0) return hash;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    var rgb = [0, 0, 0];
    for (i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 255;
        rgb[i] = value;
    }
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;
  }

}
