import objectPath from "object-path";
import React from "react";
import { withRouter } from "react-router-dom";
//import * as constants from '../../constants/constants';
import { Badge, Box, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ForumIcon from "@material-ui/icons/Forum";
import { withSnackbar } from "notistack";
import Masonry from "react-masonry-component";
import AppCard from "../../../components/AppCard";
import SectionHeading from "../../../components/SectionHeading";
import DiscussionCardContent from "../../../components/discussion/DiscussionCardContent";
import RespondentsProjectChallange from "../../../components/user/RespondentsProjectChallange";
import styles from "../../../theme/styles";
import ArrayUtil from "../../../utils/ArrayUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";


class ProjectDiscussionsMasonry extends React.Component {

  getFilteredDiscussions = () => {
    const { discussions, project } = this.props;
    return ArrayUtil.isNonEmptyArray(discussions) ? discussions.filter(
      (item) => (AccessUtil.isAtLeastObserverForProject(project.id) || (AccessUtil.isRespondentForProject(project.id) && AccessUtil.isDiscussionPublished(item) && !AccessUtil.isDiscussionLocked(item)))
    ) : [];
  }

  getListItems = () => {
    const { profiles, project, userInfo, onDeleteClicked } = this.props;
    const filteredDiscussions = this.getFilteredDiscussions();
    return ArrayUtil.sortByNumberProp(filteredDiscussions, "orderNumber", false).map(
      (item, index) => {
        const discussionMessageTotalCount = UsersUtil.getTotalDiscussionMessagesCount(
          profiles,
          project.id,
          item.id
        );
        return (
          <div className="discussionCard-wrap masonry-item" key={index}>
            <AppCard
              additionalClasses={
                (AccessUtil.isRespondentForProject(project.id) ?
                (UsersUtil.getUserProjectDiscussionMessagesCount(
                  objectPath.get(userInfo, "profile", null),
                  project.id,
                  item.id
                ) < 1
                  ? "respondent-discussion-without-reaction"
                  : "respondent-discussion-witht-reaction") : "")
                  +
                  (AccessUtil.isDiscussionPublished(item) ? " published" : " unpublished")
              }
            >
              <Badge
                color="primary"
                size="large"
                className="badge"
                invisible={discussionMessageTotalCount < 1}
                badgeContent={
                  <Typography variant="body2" className="text-white">
                    {discussionMessageTotalCount}
                  </Typography>
                }
              >
                <DiscussionCardContent
                  index={objectPath.get(item, "orderNumber", 0)}
                  project={project}
                  item={item}
                  onDeleteClick={onDeleteClicked ? onDeleteClicked : null}
                />
              </Badge>
            </AppCard>
          </div>
        );
      }
    );
  }

  render() {
    const { profiles, project, headingText, showAsMasonry } = this.props;

    const masonryOptions = {
      itemSelector: ".masonry-item",
      columnWidth: ".masonry-item-sizer",
      percentPosition: true,
      horizontalOrder: true,
    };

    return (
      <React.Fragment>
        {
          this.getFilteredDiscussions().length ? <SectionHeading
            text={headingText}
            icon={<ForumIcon />}
          /> : null
        }
        {showAsMasonry ? <Box px={2}><Masonry
          //ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
          className={"masonry-grid-wrap"} // default ''
          options={masonryOptions} // default {}
        >
          <div className="masonry-item-sizer"></div>
          {this.getListItems()}
        </Masonry></Box> : <Box px={2}>{this.getListItems()}</Box>}
        
        <RespondentsProjectChallange
          projectId={project.id}
          profiles={profiles}
        />

      </React.Fragment>
    );
  }
}

export default withRouter(
  withSnackbar(
    withStyles(styles, { withTheme: true })(ProjectDiscussionsMasonry)
  )
);
