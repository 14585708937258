import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as chatActions from "../../../actions/chat";
import * as discussionActions from "../../../actions/discussion";
import * as notificationActions from "../../../actions/notification";
import * as projectActions from "../../../actions/project";
import * as formNames from "../../../constants/forms";
import ChatForm from "../../../forms/chat/form";
import styles from '../../../theme/styles';

import { Box, Button, FormControl, Hidden, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemText, MenuItem, Paper, Select, Typography } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import ContactsIcon from '@material-ui/icons/Contacts';
import { Alert } from '@material-ui/lab';
import classnames from 'classnames';
import { withSnackbar } from "notistack";
import objectPath from 'object-path';
import { withRouter } from "react-router-dom";
import { change } from 'redux-form';
import ComponentLoading from "../../../components/ComponentLoading";
import FullScreenDialog from "../../../components/FullScreenDialog";
import ChatMessagesList from "../../../components/chat/ChatMessagesList";
import SmallDialog from "../../../components/dialogs/SmallDialog";
import MessageItem from "../../../components/messages/MessageItem";
import UserAvatar from "../../../components/user/UserAvatar";
import ArrayUtil from "../../../utils/ArrayUtil";
import DateUtil from "../../../utils/DateUtil";
import SnackBarUtil from '../../../utils/SnackBarUtil';
import StoreUtil from "../../../utils/StoreUtil";
import TranslatorUtil from "../../../utils/TranslatorUtil";
import UiHelperUtil from "../../../utils/UiHelperUtil";
import AccessUtil from "../../../utils/projectBased/AccessUtil";
import CompanyUtil from "../../../utils/projectBased/CompanyUtil";
import EmailUtil from "../../../utils/projectBased/EmailUtil";
import UrlBuilderUtil from "../../../utils/projectBased/UrlBuilderUtil";
import UsersUtil from "../../../utils/projectBased/UsersUtil";
import NotificationUtil from "../../../utils/projectBased/NotificationUtil";

const entityFormName = formNames.CHAT_CONVERSATION_FORM_NAME;


class Chat extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loading: true,
      selectedChatConversation: null,
      aboutMessage: null,
      messageDialogPreview: null,
      showModalContacts: false,
      discussions: [],
      discussionId: null
    }
  }

  getMessageToUserId = () => {
    const { match, profiles } = this.props;
    const { selectedChatConversation } = this.state;
    var userId = selectedChatConversation ? selectedChatConversation.userIds.find(x => x !== UsersUtil.getCurrentUserUid()) : objectPath.get(match,'params.userToId',null);
    return profiles.find(x => x.uid === userId) 
    && userId 
    && UsersUtil.getCurrentUserUid() !== userId //not current user
    ? userId : null;
  }

  getConversationAnotherUserId = (conversation) => {
    var currentUserId = UsersUtil.getCurrentUserUid();
    var userIds = objectPath.get(conversation, 'userIds',[]);
    return userIds.find(x => x !== currentUserId);
  }

  scrollMessagerToTop = (eId = "chatMessagesWrap") => {
    setTimeout(() => {
      window.scrollTo(0, 0);
      if(document.getElementById(eId)){
        document.getElementById(eId).scrollTo(0, 0);
      }
    }, 300);
  }

  componentDidMount(){
    const { match, actions } = this.props;
    const projectId = objectPath.get(match, 'params.projectId', null);
    this.setState({
      loading: true
    });
    Promise.all([
      projectId ? actions.getProject(projectId) : Promise.resolve(),
      this.listenChatConversations(),
      this.getAboutMessage()
    ]).then(() => {
      if(!AccessUtil.hasAccessToProject(null, projectId, UsersUtil.getCurrentUserEmail())){
        AccessUtil.redirect();
      } else {
        this.setState({
          loading: false
        });
        this.scrollMessagerToTop();
      }
    }
    );
  }

  componentWillReceiveProps(nextProps){
    this.redirectToLatest(nextProps);
    this.mountCurrentChatConversation(nextProps);
  }

  getAboutMessage = () => {
    const { actions,match } = this.props;
    var projectId = objectPath.get(match, 'params.projectId', null);
    var discussionId = objectPath.get(match, 'params.discussionId', null);
    var messageId = objectPath.get(match, 'params.messageId', null);
    if(projectId && discussionId && messageId){
      return actions.getDiscussionMessage(projectId , discussionId , messageId).then((response) => {
        this.setState({
          aboutMessage: objectPath.get(response,'data',null)
        });
      });
    }
    return Promise.resolve();
  }


  redirectToLatest = (nextProps) => {
    const { chatConversations, match, history } = nextProps || this.props;
    var messageId = objectPath.get(match, 'params.messageId', null);
    var conversationId = objectPath.get(match, 'params.conversationId', null);
    var projectId = objectPath.get(match, 'params.projectId', null);
    var currentUserId = UsersUtil.getCurrentUserUid();

    if(messageId && ArrayUtil.isNonEmptyArray(chatConversations)){
      const newConversationId = this.getNewConversationId();
      console.log('newConversationId', newConversationId);
      const conversationExistsAlready = chatConversations.find(x => messageId && x.projectId === projectId && x.id === this.getNewConversationId());
      if(conversationExistsAlready && objectPath.get(conversationExistsAlready, 'id', null) === newConversationId){
        return history.replace(UrlBuilderUtil.getChatById(newConversationId, projectId));
      }
    }
    
    
    
    if(messageId === null && currentUserId && conversationId === null && ArrayUtil.isNonEmptyArray(chatConversations) && projectId){
      var latest = ArrayUtil.sortByDateUpdated(chatConversations)[0];
      if(latest && ArrayUtil.isNonEmptyArray(latest.userIds)){
        history.push(UrlBuilderUtil.getChatById(latest.id, projectId));
      }
    }
  }

  mountCurrentChatConversation = (nextProps) => {
    const { chatConversations, match, actions } = nextProps || this.props;
    const { selectedChatConversation } = this.state;
      if(chatConversations && chatConversations.length){
        // var currentUserId = UsersUtil.getCurrentUserUid();
        var conversationId = objectPath.get(match, 'params.conversationId');
        
        
        var newSelectedChatConversation = chatConversations.find(
          x => x.id === conversationId
        );

        //console.log('mountCurrentChatConversation', {userInfo, chatConversations, match, currentUserId, userToId, newSelectedChatConversation})

        if(objectPath.get(selectedChatConversation,'id',null) !== objectPath.get(newSelectedChatConversation,'id',null) && newSelectedChatConversation){
          console.log('mount current chat conversation', newSelectedChatConversation.id);
          this.scrollMessagerToTop();
          this.setState({
            selectedChatConversation : newSelectedChatConversation
          });
          if(newSelectedChatConversation){
            actions.listenChatConversationMessages(newSelectedChatConversation.id);
          }
        }
        
      }
    
    
    
  }


  componentWillUnmount(){
    //const { actions } = this.props;
    //maybe remove listening
    //actions.flushDiscussionMessages()
  }


  listenChatConversations = () => {
    const { actions, match } = this.props;
    const projectId = objectPath.get(match, 'params.projectId', null);
    if(UsersUtil.getCurrentUserUid() && projectId){
      actions.listenChatConversationsByProject(projectId, UsersUtil.getCurrentUserUid());
      if(AccessUtil.isModeratorForProject(projectId, false)){
        actions.getDiscussions(projectId).then(result => {
          this.setState({
            discussions: objectPath.get(result,'data',[])
          })
        });  
      }
    }
  }



  handleResponse = (response, values) => {
    const { enqueueSnackbar } = this.props;
    const { aboutMessage } = this.state;
    if(!SnackBarUtil.isResponseError(response, enqueueSnackbar)){
      this.addMessageNotification(values,this.getMessageToUserId());
      enqueueSnackbar(TranslatorUtil.t("Message has been sent"), { variant: "success"});
      this.scrollMessagerToTop();
      if(aboutMessage){
        this.setState({ aboutMessage: null });
        
      }
    }
  }

  addMessageNotification = async (message,toUserId) => {
    const { actions, userInfo } = this.props;
    const { selectedChatConversation } = this.state;
    const conversationId = objectPath.get(selectedChatConversation,'id',null);
    if(conversationId){
      const isNewNotification = await NotificationUtil.isNewNotification(conversationId);
      const projectId = objectPath.get(selectedChatConversation,'projectId',null);
      const senderRole = AccessUtil.getUserRoleForProject(projectId, UsersUtil.getCurrentUserEmail(), false);
      const notifObject = EmailUtil.getChatNotification(
        CompanyUtil.getId(),
        UsersUtil.getUserEmailFromStoreById(toUserId),
        message,
        UsersUtil.getUserDisplayNameByUserInfo(userInfo) + (TranslatorUtil.t(senderRole) ? " (" + TranslatorUtil.t(senderRole)+ ")" : ""),
        projectId,
        objectPath.get(selectedChatConversation,'id',null),
        objectPath.get(selectedChatConversation,'messageText',null),
        );
      if(isNewNotification){
        notifObject.createdDate = DateUtil.timestamp();
      }
      return actions.putNotification(conversationId,notifObject);
    } else {
      return Promise.resolve();
    }
  }

  getNewConversationId = () => {
    const { match } = this.props;
    let conversationUserIds = [UsersUtil.getCurrentUserUid(), this.getMessageToUserId()].sort();
    const projectId = objectPath.get(match, 'params.projectId', null);
    const discussionId = objectPath.get(match, 'params.discussionId', null);
    const messageId = objectPath.get(match, 'params.messageId', null);
    return messageId ? conversationUserIds.join('_')+'_p_'+projectId+'_d_'+discussionId+'_m_'+messageId : null;
  }

  createConversation = () => {
    const { actions, match, history } = this.props;
    const { aboutMessage } = this.state;
    let conversationUserIds = [UsersUtil.getCurrentUserUid(), this.getMessageToUserId()].sort();
    const projectId = objectPath.get(match, 'params.projectId', null);
    const discussionId = objectPath.get(match, 'params.discussionId', null);
    const messageId = objectPath.get(match, 'params.messageId', null);
    if(aboutMessage){
      this.setState({ loading: true });
      const newConversationId = conversationUserIds.join('_')+'_p_'+projectId+'_d_'+discussionId+'_m_'+messageId;
      const newConversation = {
        userIds: conversationUserIds,
        projectId,
        discussionId,
        messageId,
        messageText: objectPath.get(aboutMessage, 'message', null),
        messageByUserId: objectPath.get(aboutMessage, 'byUserId', null)
      };
      return actions.postChatConversationById(newConversationId,newConversation).then(() => {
        this.setState({ aboutMessage: null });
        history.push(UrlBuilderUtil.getChatById(newConversationId, projectId));
      }).finally(() => {
        this.setState({ loading: false });
      });
    }
    return Promise.resolve();
  }


  handleSubmit = values => {
    const { actions, chatConversations } = this.props;
    const { selectedChatConversation } = this.state;
    
    var projectId = objectPath.get(selectedChatConversation, 'projectId', null);
    var discussionId = objectPath.get(selectedChatConversation, 'discussionId', null);
    var messageId = objectPath.get(selectedChatConversation, 'messageId', null);
    const currentUserId = UsersUtil.getCurrentUserUid();
    values.directChatMessage = true;

    values.byUserId = currentUserId;
    if(selectedChatConversation){
      values.projectId = projectId;
      values.discussionId = discussionId;
      values.userIds = objectPath.get(selectedChatConversation, 'userIds',[]);
      values.messageId = messageId;
      // values.aboutMessageInit = aboutMessage ? true : false;
      // actions.updateChatConversationUpdateTime(selectedChatConversation.id);
      return actions.updateChatConversationByNewMessage(chatConversations.find(x => x.id === selectedChatConversation.id), values)
      .then(() => actions.postChatConversationMessage(selectedChatConversation.id,values).then((response)=>this.handleResponse(response, values)))
    }
    
  };


  cancelAboutMessage = () => {
    const { history } = this.props;
    this.setState({ aboutMessage: null });
    const hasHistory = StoreUtil.getValueByKey('routerLocations.1',null);
    if(hasHistory){
      history.goBack();
    } else {
      history.push(UrlBuilderUtil.getDashboard());
    }
  }

  prependWithPerson = (conversation, currentUserId) => {
    return (currentUserId === objectPath.get(conversation,'lastMessageBy') ? TranslatorUtil.t('You')+': ': '');
  }

  renderConverations = () => {
    const { chatConversations, history, match } = this.props;
    const { selectedChatConversation, discussions, discussionId} = this.state;
    const projectId = objectPath.get(match, 'params.projectId', null);
    var currentUserId = UsersUtil.getCurrentUserUid();
    const filteredChatConversations = discussionId ? chatConversations.filter((x) => x.discussionId === discussionId) : chatConversations;
    return <List disablePadding={true}>
      {(AccessUtil.isModeratorForProject(projectId, false) && ArrayUtil.isNonEmptyArray(discussions) && discussions.length > 1) && <ListItem>
        <ListItemText primary={<FormControl className="w-100">
                  <Select
                    labelId="dicussions-select-label"
                    value={discussionId ? discussionId : "all"}
                    onChange={(event) =>
                      this.setState({
                        discussionId:
                          event.target.value !== "all"
                            ? event.target.value
                            : null,
                        selectedChatConversation: null,
                        discussion:
                          event.target.value !== "all"
                            ? discussions.find(
                                (x) => x.id === event.target.value
                              )
                            : null,
                      })
                    }
                  >
                    <MenuItem value={"all"} key={0}>
                      <em>{TranslatorUtil.t("All discussions")}</em>
                    </MenuItem>
                    {discussions.map((discussion) => (
                      <MenuItem value={discussion.id} key={discussion.id}>
                        {discussion.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>}></ListItemText>
        </ListItem>}
       {filteredChatConversations && filteredChatConversations.length ? 
      ArrayUtil.sortByDateUpdated(filteredChatConversations).map((conversation, index) => {
        
        var anotherUserId = this.getConversationAnotherUserId(conversation);
        //console.log('conversation',conversation, anotherUserId);
        return <ListItem divider={true} className={conversation.id === objectPath.get(selectedChatConversation,'id',null) ? "active":""} key={index} onClick={() => {
          this.setState({showModalContacts:false});
          history.push(UrlBuilderUtil.getChatById(conversation.id, projectId));
        }}>
          <ListItemIcon>
            <UserAvatar userId={anotherUserId} />
          </ListItemIcon>
          <ListItemText primary={UsersUtil.getUserDisplayNameById(anotherUserId)} secondary={
            <React.Fragment>{conversation.lastMessageText ? 
          <div><small>{UiHelperUtil.truncateIfNeeded(this.prependWithPerson(conversation,currentUserId) + conversation.lastMessageText, 22)}</small></div>
           : null}
           
            {conversation.messageText ? 
           <div className="border-top"><small><em>{UiHelperUtil.truncateIfNeeded(conversation.messageText, 22)}</em></small></div> : null}
          </React.Fragment>} />
      </ListItem>})
    : <ListItem><Alert severity="warning">{TranslatorUtil.t('No conversation started yet')}</Alert></ListItem>}</List>
  }

  

  getMessagePreview = () => {
    const { aboutMessage } = this.state;
    
    return aboutMessage ? <Box className="narrowMaxMediumWidthPart"><MessageItem message={aboutMessage} disableAvatarActions={true} disableReactions={true} /></Box> : null;
  }


  currentConversationUserPreview = () =>{
    return <div>
    <List className={"narrowMaxMediumWidthPart"} dense={true} disablePadding={true}>
     
     
       <ListItem alignItems="flex-start" dense={true} disableGutters={true}>
         
         <ListItemAvatar>
           <UserAvatar userId={this.getMessageToUserId()} menuItems={{withDialog: AccessUtil.isAtLeastObserver()}} /> 
         </ListItemAvatar>
         <ListItemText 
         primary={
         
         <Box>
          <Hidden mdDown>
          {TranslatorUtil.t('Conversation with')}
          </Hidden>
          <Hidden smUp>
          {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
                </Hidden>
           </Box>}
         secondary={
          <Box><Hidden mdDown>
            {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
          </Hidden>
          <Hidden smUp>
          <Button size="small" color="default" variant="outlined" startIcon={<ContactsIcon />} className="contactSelector" onClick={() => this.setState({showModalContacts:true})}>
                {TranslatorUtil.t('Select other conversation')}
                </Button>
                </Hidden>
                </Box>
          }>
         </ListItemText>
       
     </ListItem>  
     </List>
     
    </div>
  }

  showDialogMessagePreview = (projectId,discussionId,messageId) => {
    const { actions } = this.props;
    if(projectId && discussionId && messageId){
      return actions.getDiscussionMessage(projectId , discussionId , messageId).then((response) => {
        this.setState({
          messageDialogPreview: objectPath.get(response,'data',null)
        });
      });
    }
    return Promise.resolve();
  }

  cancelDialogMessagePreview = () => {
    this.setState({
      messageDialogPreview: null
    });
  }


  render() {
    const { classes, selectedChatConversationMessages, chatConversations, history } = this.props;
    const { loading, selectedChatConversation, aboutMessage, messageDialogPreview,showModalContacts } = this.state;

    // console.log("rendering chat",selectedChatConversation);

    return (
      <div>
        <Hidden smDown>
        <div className={classnames(classes.conversationsWrap)}>
        {
          this.renderConverations()
        }
        </div>
        </Hidden>
        <div className={classnames(classes.conversationWrap,"conversationInnerWrap")}>
          <div className={classes.conversationInnerWrap}>
          
          {this.getMessageToUserId() ? ( loading ? <ComponentLoading /> : selectedChatConversation ? 
      <div>
        <div id="chatMessagesWrap" className={classnames(classes.chatMessagesWrap,"chatMessagesWrap")}>
        <Hidden smDown>
            {this.currentConversationUserPreview()}
          </Hidden>
         {
          objectPath.has(selectedChatConversation,"messageText") && <Box py={2}><Paper elevation={2} component="div" className="narrowMaxMediumWidthPart" style={{display:"block"}}>
              <Box p={2}><Typography variant="caption">
                {selectedChatConversation.messageText}
              </Typography>
              <Box className="text-right">
                <Button variant="text" color="primary" size="small" onClick={() => history.push(UrlBuilderUtil.getDiscussionConversation(selectedChatConversation.projectId, selectedChatConversation.discussionId, selectedChatConversation.messageId), '_blank')}>
                  {TranslatorUtil.t("Open message in discussion")}
                </Button>
              </Box>
              </Box>
          </Paper>
          </Box>
         }
         <ChatMessagesList items={selectedChatConversationMessages.filter((m) => m.messageId === selectedChatConversation.messageId)} currentUserId={UsersUtil.getCurrentUserUid()} showDialogMessagePreview={this.showDialogMessagePreview} />
        </div>
        <div  className={classnames(classes.chatFormWrap,"chatFormWrap")}>
          
          <ChatForm 
            proceedSubmit={this.handleSubmit} 
            initialValues={null}
          />
          <Hidden mdUp>
          {this.currentConversationUserPreview()}
          </Hidden>
        </div>
      </div>
       : <div>
        {aboutMessage ? <Box className="narrowMaxMediumWidthPart aboutMessageWrapper">
            {this.getMessagePreview()}
          <IconButton aria-label="delete" onClick={this.cancelAboutMessage} size="small" className="cancelBtn">
          <ClearIcon fontSize="inherit" color="error" />
        </IconButton></Box> : null
        }
        {aboutMessage ? <Button color="secondary" variant="contained" onClick={this.createConversation}>
              <UserAvatar userId={this.getMessageToUserId()} /> {TranslatorUtil.t('Start conversation with')} {UsersUtil.getUserDisplayNameById(this.getMessageToUserId())}
            </Button> : <Alert severity="info">{TranslatorUtil.t('To start conversation please select message you want to talk about')}</Alert>}
            
            </div> ) : chatConversations && chatConversations.length ? <Alert severity="info">{TranslatorUtil.t('Select conversation please')}</Alert>: null}
          </div>
      
        </div>
        {
          messageDialogPreview ? <SmallDialog
          title={TranslatorUtil.t("Message preview")}
          handleClose={this.cancelDialogMessagePreview}
          >
            <MessageItem message={messageDialogPreview} currentUserId={UsersUtil.getCurrentUserUid()} disableAvatarActions={true} disableReactions={true} />
          </SmallDialog>
           : null
        }

        {
          showModalContacts ? <FullScreenDialog
          title={TranslatorUtil.t("Select conversation")}
          closeDialog={() => this.setState({showModalContacts:false})}
          >
            {
          this.renderConverations()
        }
          </FullScreenDialog>
           : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.userInfo,
  chatConversations: state.chatConversations,
  selectedProject: state.selectedProject,
  selectedChatConversationMessages: state.selectedChatConversationMessages,
  profiles: state.profiles
});

const mapDispatchToProps = dispatch =>
({
  changeFieldValue: function(field, value) {
    dispatch(change(entityFormName, field, value))
  },
  actions: bindActionCreators(
    {
      ...chatActions,
      ...notificationActions,
      ...discussionActions,
      ...projectActions
    },
    dispatch
  )
});


export default withRouter(withSnackbar(withStyles(styles, { withTheme: true })(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Chat)
)));