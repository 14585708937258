import * as types from "../constants/actionTypes";
import * as ajaxCalls from "./ajaxCalls.js";
import firestoreApi from '../firebase.js';
import FirestoreCollectionUtil from "../utils/FirestoreCollectionUtil";
import * as collections from '../constants/firebaseCollections';
import DateUtil from "../utils/DateUtil";
import objectPath from "object-path";
import UsersUtil from "../utils/projectBased/UsersUtil.js";
import NotificationUtil from "../utils/projectBased/NotificationUtil.js";


export function listenSupportConversations(userId) {
  return function(dispatch) {
    console.log('listenSupportConversations called '
    //,userId
    );
    return firestoreApi.collection(collections.SUPPORT)
    .where("userIds", "array-contains", userId)
    .onSnapshot(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_SUPPORT_CONVERSATIONS)))
  };
}

export function getSupportConversations(userId) {
  return function(dispatch) {
    console.log('getSupportConversations called '
    //,userId
    );
    return firestoreApi.collection(collections.SUPPORT)
    .where("userIds", "array-contains", userId)
    .limit(30)
    .orderBy("updatedTime", "desc")
    .get().then(response => dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_SUPPORT_CONVERSATIONS)),
    error => dispatch(ajaxCalls.ajaxCallError(error, types.GET_SUPPORT_CONVERSATIONS)))
  };
}

export function listenSupportConversationMessages(supportId) {
  return function(dispatch) {
    console.log('listenSupportConversationMessages called '
    //,supportId
    );
    return firestoreApi
    .collection(
        [collections.SUPPORT,supportId,collections.MESSAGES].join('/')
    ).limit(30)
    .orderBy("createdTime", "desc")
      .onSnapshot(response => {
        setTimeout(() => NotificationUtil.markConversationAndNotificationAsRead(supportId, UsersUtil.getCurrentUserUid(), UsersUtil.getCurrentUserEmail(), collections.SUPPORT), 1000);
        return dispatch(ajaxCalls.ajaxCallSuccess(FirestoreCollectionUtil.parseCollectionData(response), types.GET_SUPPORT_CONVERSATION_MESSAGES))
      });
  };
}


export function updateSupportConversationByNewMessage(support,message){
  return function(dispatch) {
    console.log('updateSupportConversationByNewMessage '
    //,userId
    );
    const currentUserId = objectPath.get(message,'byUserId',null);
    const currentTimeStamp = DateUtil.timestamp();
    const updateConversation = {
      lastMessageText: objectPath.get(message,'message',""),
      lastMessageBy: currentUserId,
    };
    updateConversation["readTime_"+currentUserId] = currentTimeStamp;
    // update only if lastMessageByUserId is present as mail sent by new notification
    if(objectPath.has(support, 'lastMessageBy')){ 
      updateConversation.updatedTime = currentTimeStamp;
    }
    return firestoreApi.collection(
      collections.SUPPORT
    ).doc(support.id).update(updateConversation).then(
      response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.PATCH_SUPPORT_CONVERSATION)),
      error => dispatch(ajaxCalls.ajaxCallError(error, types.PATCH_SUPPORT_CONVERSATION))
    );
  };
}

export function clearSupportConversationMessages(){
  console.log('clearSupportConversationMessages called ');
  return { type: types.GET_SUPPORT_CONVERSATION_MESSAGES, data: [] };
}


export function postSupportConversationMessage(supportId,data) {
  return function(dispatch) {
    console.log('postSupportConversationMessage'
    //,[collections.SUPPORT,supportId,collections.MESSAGES].join('/')
    );
    data.createdTime = DateUtil.timestamp();
    data.updatedTime = DateUtil.timestamp();
    // this should not be run as every time it is called after updateSupportConversationByNewMessage which does it all
    // const updateConversation = {
    //   lastMessageText: data.message,
    //   lastMessageBy: objectPath.get(data,'byUserId',null),
    //   updatedTime: DateUtil.timestamp()
    // };
    // if(objectPath.get(data,'byUserId',null)){
    //   updateConversation["readTime_"+objectPath.get(data,'byUserId',null)] = DateUtil.timestamp();
    // }
    // firestoreApi.collection(
    //   collections.SUPPORT
    // ).doc(supportId).update(updateConversation);
    return firestoreApi.collection(
        [collections.SUPPORT,supportId,collections.MESSAGES].join('/')
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_SUPPORT_CONVERSATION_MESSAGE)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_SUPPORT_CONVERSATION_MESSAGE))
      );
  };
}

export function postSupportConversation(data) {
  return function(dispatch) {
    console.log('postSupportConversation '
    //,data
    );
    data.createdTime = DateUtil.timestamp();
    data.updatedTime = DateUtil.timestamp();
    return firestoreApi.collection(
        collections.SUPPORT
    ).add(data)
      .then(
        response => dispatch(ajaxCalls.ajaxCallSuccess(response, types.POST_SUPPORT_CONVERSATION)),
        error => dispatch(ajaxCalls.ajaxCallError(error, types.POST_SUPPORT_CONVERSATION))
      );
  };
}
